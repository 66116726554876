class genericIndicatorPage extends Component {

    static name() {
        return "genericIndicatorPage";
    }

    static componentName() {
        return "genericIndicatorPage";
    }

    getTemplate() {
        return `<div class="indicatorPage"> 
                  <div :class="classContainer">
                      <div class="row justify-content-center section main">
                          <template v-if="$store.getters.getAppState=='ready'">
                            <div class="section white">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12">
                                             <template v-for="indicator of $store.getters.getIndicators">
                                                <indicator-component :indicator="indicator">
                                                    <template slot="parameters" scope="reportScope">
                                                        <genericIndicatorForm :reportScope="reportScope" :reportName="indicator.fields.ReportName" ></genericIndicatorForm>
                                                    </template>
                                                </indicator-component>  
                                             </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </template>
                          <template v-else>
                            <div class="container center section white" style="min-height: 60%;" >
                               <h3>{{tr('Page loading please wait')}}</h3>
                             </div>
                          </template>
                      </div>
                  <div>
              </div>`;
    }
}

genericIndicatorPage.registerComponent();